var render = function () {
  var _vm$fetchHistoryTrans, _vm$fetchHistoryTrans2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CBox', {
    attrs: {
      "font-family": "Roboto",
      "color": "#111",
      "w": "100%",
      "px": ['0px', '120px'],
      "py": ['0px', '30px'],
      "bg": ['#F2F2F2', 'white'],
      "h": ['calc(100vh - 126px)', '100%'],
      "border": ['none', '1px solid #f2f2f2'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['none', '16px']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: {
          name: 'client.index'
        }
      }, {
        label: 'Akun',
        href: {
          name: 'client.profile'
        }
      }, {
        label: 'Riwayat Transaksi',
        isCurrent: true
      }]
    }
  })], 1), _c('CHeading', {
    attrs: {
      "as": "h1",
      "font-weight": "700",
      "font-size": "28px",
      "d": ['none', 'block'],
      "mx": ['16px', '0px'],
      "font-family": "Roboto"
    }
  }, [_vm._v(" Riwayat Transaksi ")]), _c('CBox', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll"
    }],
    attrs: {
      "pb": "8px",
      "pt": "16px",
      "px": ['16px', '0px'],
      "overflow": "hidden",
      "position": ['unset', 'relative'],
      "h": ['unset', '82px']
    }
  }, [_c('CList', {
    attrs: {
      "d": "flex",
      "gap": "4px",
      "position": ['unset', 'absolute']
    }
  }, _vm._l(_vm.LIST_FILTER, function (filter) {
    return _c('CListItem', {
      key: filter.id,
      staticStyle: {
        "list-style": "none"
      }
    }, [_c('CBox', {
      class: {
        'filter-active': _vm.selectedFilter === filter.id
      },
      attrs: {
        "as": "button",
        "bg": "white",
        "border": "2px solid #008C81",
        "px": ['8px', '20px'],
        "py": ['6px', '11px'],
        "rounded": "1000px",
        "font-size": ['12px', '16px'],
        "font-weight": "400",
        "white-space": "nowrap",
        "color": "#008C81",
        "font-family": "Roboto"
      },
      on: {
        "click": function click($event) {
          return _vm.handleChangeFilter(filter.id);
        }
      }
    }, [_vm._v(" " + _vm._s(filter.label) + " ")])], 1);
  }), 1)], 1), ((_vm$fetchHistoryTrans = _vm.fetchHistoryTransactionsQuery.data) === null || _vm$fetchHistoryTrans === void 0 ? void 0 : (_vm$fetchHistoryTrans2 = _vm$fetchHistoryTrans.value) === null || _vm$fetchHistoryTrans2 === void 0 ? void 0 : _vm$fetchHistoryTrans2.length) > 0 ? _c('CBox', {
    attrs: {
      "px": ['16px', '16px'],
      "mt": ['0px', '16px'],
      "mb": ['16px', '0px'],
      "bg": ['#F2F2F2', 'white']
    }
  }, [_vm._l(_vm.fetchHistoryTransactionsQuery.data.value, function (item) {
    return _c('CBox', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':not(:last-child)': {
            marginBottom: '30px'
          }
        },
        expression: "{\n          ':not(:last-child)': {\n            marginBottom: '30px',\n          }\n        }"
      }],
      key: item.invoice,
      attrs: {
        "p": ['16px', '32px'],
        "rounded": "8px",
        "box-shadow": "0px 5px 15px rgb(0 0 0 / 20%)",
        "bg": "white",
        "display": "flex",
        "gap": "16px",
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          return _vm.handleClickHistory(item.id);
        }
      }
    }, [_c('CImage', {
      attrs: {
        "object-fit": "cover",
        "h": "230.35px",
        "w": "180px",
        "src": item.image,
        "alt": "Image",
        "rounded": "6px",
        "display": ['none', 'block']
      }
    }), _c('CBox', {
      attrs: {
        "width": "100%"
      }
    }, [_c('CBox', {
      attrs: {
        "d": "flex",
        "align-items": "center",
        "justify-content": "space-between",
        "gap": "8px"
      }
    }, [_c('CBox', {
      attrs: {
        "display": "flex",
        "gap": "8px",
        "align-items": "center"
      }
    }, [_c('CImage', {
      attrs: {
        "object-fit": "cover",
        "h": "50px",
        "w": "50px",
        "src": item.image,
        "alt": "Image",
        "rounded": "6px",
        "display": ['block', 'none']
      }
    }), _c('CText', {
      attrs: {
        "font-size": ['16px', '28px'],
        "font-weight": ['600', '700'],
        "d": "flex",
        "align-items": "center",
        "gap": "8px",
        "flex-wrap": "wrap",
        "font-family": "Roboto"
      }
    }, [_vm._v(" " + _vm._s(item.productName || '-') + " "), _c('CText', {
      attrs: {
        "font-size": ['16px', '20px'],
        "font-weight": ['600', '700']
      }
    }, [_vm._v(" (" + _vm._s(item.name || '-') + ") ")])], 1)], 1), _c('LabelProgramStatus', {
      attrs: {
        "status": _vm.checkStatusProgram(item.status, item.program)
      }
    })], 1), _c('CDivider', {
      attrs: {
        "border-color": "lightGray"
      }
    }), _c('CBox', {
      staticStyle: {
        "border-collapse": "separate",
        "border-spacing": "0px 8px"
      },
      attrs: {
        "as": "table",
        "font-size": ['12px', '16px'],
        "font-weight": "400",
        "font-family": "Roboto"
      }
    }, [_c('CBox', {
      attrs: {
        "as": "tr"
      }
    }, [_c('CBox', {
      staticStyle: {
        "display": "inline-block",
        "margin-right": "16px"
      },
      attrs: {
        "as": "th"
      }
    }, [_vm._v(" Invoice ")]), _c('CBox', {
      attrs: {
        "as": "td",
        "color": "#333333"
      }
    }, [_vm._v(" " + _vm._s(item.invoice) + " ")])], 1), _c('CBox', {
      attrs: {
        "as": "tr"
      }
    }, [_c('CBox', {
      staticStyle: {
        "display": "inline-block",
        "margin-right": "16px"
      },
      attrs: {
        "as": "th"
      }
    }, [_vm._v(" Durasi ")]), _c('CBox', {
      attrs: {
        "as": "td",
        "color": "#333333"
      }
    }, [_vm._v(" " + _vm._s(item.duration) + " hari ")])], 1), _c('CBox', {
      attrs: {
        "as": "tr"
      }
    }, [_c('CBox', {
      staticStyle: {
        "display": "inline-block",
        "margin-right": "16px"
      },
      attrs: {
        "as": "th",
        "text-align": "start"
      }
    }, [_vm._v(" Tanggal Transaksi ")]), _c('CBox', {
      attrs: {
        "as": "td",
        "color": "#333333"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatDateV2(item.date)) + " ")])], 1), _c('CBox', {
      attrs: {
        "as": "tr"
      }
    }, [_c('CBox', {
      staticStyle: {
        "display": "inline-block",
        "margin-right": "16px"
      },
      attrs: {
        "as": "th"
      }
    }, [_vm._v(" Status ")]), _c('CBox', {
      attrs: {
        "as": "td"
      }
    }, [_c('LabelTransactionStatus', {
      attrs: {
        "status": item.status
      }
    })], 1)], 1), _c('CBox', {
      attrs: {
        "as": "tr"
      }
    }, [_c('CBox', {
      staticStyle: {
        "display": "inline-block",
        "margin-right": "16px"
      },
      attrs: {
        "as": "th"
      }
    }, [_vm._v(" Harga ")]), _c('CBox', {
      attrs: {
        "as": "td",
        "color": "#333333"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.price)) + " ")])], 1)], 1)], 1)], 1);
  }), _c('CBox', {
    attrs: {
      "display": ['block', 'none'],
      "height": "80px"
    }
  }, [_vm._v("   ")])], 2) : _c('EmptyCard', {
    attrs: {
      "label": "Riwayat Transaksi tidak tersedia"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }